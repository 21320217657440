import React from "react"
import styled from "styled-components"
import { BorderRadius, Colors, Shadows, Spacing } from "../rules"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fixed(width: 150) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const Sponsors = ({withButton = true}) => {
  const data = useStaticQuery(graphql`
    query {
      alberto_alvarez_logo: file(
        relativePath: { eq: "sponsors/alvarez-logo.png" }
      ) {
        ...fluidImage
      }

      codef_logo: file(relativePath: { eq: "sponsors/codef_logo.png" }) {
        ...fluidImage
      }

      santiago_logo: file(
        relativePath: { eq: "sponsors/santiago.png" }
      ) {
        ...fluidImage
      }

      cultivo_logo: file(
        relativePath: { eq: "sponsors/cultivo-logo.png" }
      ) {
        ...fluidImage
      }
    }
  `)
  const sponsors = [
    {
      id: 1,
      name: "Alberto Alvarez Inmobiliaria",
      photo: data.alberto_alvarez_logo.childImageSharp.fixed,
      link: "https://albertoalvarez.com/",
    },
    {
      id: 2,
      name: "Codef",
      photo: data.codef_logo.childImageSharp.fixed,
      link: "https://www.instagram.com/codef/",
    },
    {
      id: 3,
      name: "Santiago Villada - Consultador en seguros",
      photo: data.santiago_logo.childImageSharp.fixed,
      link: "https://www.instagram.com/sv_seguros/",
    },
    {
      id: 4,
      name: "Cultivo Alimentos Naturales",
      photo: data.cultivo_logo.childImageSharp.fixed,
      link: "https://www.instagram.com/cultivo_alimentos/",
    },
  ]

  return (
    <Container>
      <LogoLinks>
        {sponsors.map(sponsor => {
          if (sponsor.photo) {
            return (
              <Sponsor key={sponsor.id} href={sponsor.link}>
                <Img fixed={sponsor.photo} alt={sponsor.name} />
              </Sponsor>
            )
          }
        })}
      </LogoLinks>
      {withButton && (
        <Link to={"/sponsors"}>
          <ButtonLink>Descubre nuestros sponsors</ButtonLink>
        </Link>
      )}
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${Spacing.lg} 0;
`;

const LogoLinks = styled("div")`
  display: grid;
  grid-template-columns: 234px 234px 234px 234px;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  @media (max-width: 1080px) {
    grid-template-columns: 234px 234px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 234px;
  }
  justify-items: center;
  align-items: center;
  margin: ${Spacing.md};
`

const Sponsor = styled("a")`
  margin: 0 ${Spacing.lg};
  width: 150px;
`

const ButtonLink = styled("div")`
  background-color: ${Colors.primary.blue.darker};
  border-radius: ${BorderRadius.md};
  box-shadow: ${Shadows.level2};
  padding: ${Spacing.md} ${Spacing.lg};
  text-align: center;
  text-decoration: none;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-top: ${Spacing.md};
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${Shadows.level4};
    background-color: ${Colors.primary.blue.default};
  }
`

export default Sponsors
