import React from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import TopBar from "../components/TopBar"
import Layout from "../containers/Layout"

import { Colors, Spacing, Typography } from "../rules"
import Sponsors from "../components/Sponsors"

const SponsorsPage = () => {
  return (
    <Layout>
      <TopBar />
      <Main>
        <Title>Sponsors</Title>
        <Section margin={"0 " + Spacing.xl + " " + Spacing.md}>
          <h1>Aliados Fénix RC</h1>
          <p>Muchas gracias a todos nuestros aliados FRC</p>
          <Sponsors withButton={false} />
          <p>¿Quieres ser parte de esta familia?{" "}
            <a
              href={
                "https://wa.me/573183472689?text=Sponsor%20Fénix%20Rugby%20Club"
              }
            >
              Comunicate con nosotros para mas informacion.
            </a>
          </p>
        </Section>
      </Main>
      <Footer />
    </Layout>
  )
}

const Main = styled("div")`
  flex: 1 0 auto;
  justify-content: center;
  color: ${Colors.utility.paragraph.default};
  a {
    color: ${Colors.primary.blue.default};
  }
`

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  border: ${props => props.border && props.border};
  border-width: ${props => props.borderWidth && props.borderWidth};
  margin: ${props => props.margin && props.margin};
`

const Title = styled("div")`
  text-align: center;
  margin: ${Spacing.lg} 0;
  font-size: ${Typography.title.fontSize};
  font-weight: 600;
`

export default SponsorsPage
